import React from "react"
import Layout from "../components/Layout"
import Login from "../components/Modals/Login"

const LoginPage = props => {
  return (
    <Layout>
      <Login />
    </Layout>
  )
}

export default LoginPage
